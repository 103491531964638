<template>
  <div id="data-list-list-view">
  

    <div>
      <vx-card class="flex-1" v-bind:title="$t('Scans')">
        
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="PatientMedicalHistoryScans"
        >
          <template slot="thead">
            <vs-th> {{ $t("Doctor") }}</vs-th>
           <vs-th> {{ $t("Description") }}</vs-th>
            <vs-th> {{ $t("Scan Type") }}</vs-th>
            <vs-th> {{ $t("Path") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p v-if="tr.Doctor" class="product-name font-medium truncate">
                    {{ tr.Doctor.Name }}
                  </p>
                </vs-td>
                  <vs-td>
                  <p v-if="tr.Description" class="product-name font-medium truncate">
                    {{ tr.Description }}
                  </p>
                </vs-td>
                 <vs-td>
                  <p v-if="tr.ScanType" class="product-name font-medium truncate">
                    {{ tr.ScanType.Name }}
                  </p>
                </vs-td>
             
                 <vs-td>
                      <!-- <a :href="tr.URL" download>Download</a> -->
                      <a :href="baseURL + tr.AttachPath" download target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a> 
                    </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";

export default {
  components: {
    VuePerfectScrollbar,
    Datepicker

    // vSelect,
  },
  props: {
    patientMedicalHistory: {
      type: Object,
      required: true
    }
  },
  computed: {
   PatientMedicalHistoryScans() {
      return this.$store.state.patientList.PatientMedicalHistoryScans;
    },
   
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: []
      },
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},
      experienceModel: {},
      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile"
    };
  },
  methods: {
 
  },
  created() {
  
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
