<template>
  <div>
    <vs-alert
      color="danger"
      title="Course Not Found"
      :active.sync="patient_not_found"
    >
      <span>patient record with id: {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'Patient' }" class="text-inherit underline"
          >All patient</router-link
        >
      </span>
    </vs-alert>
    <div
      v-if="!patient_not_found || (this.$route.params.ID && this.patientMedicalHistory.ID)"
    >
                  <feather-icon icon="ArrowLeftIcon" svgClasses="h-8" style="cursor:pointer" @click="$router.push('/Patient')"/>
     <patient-Tab
                  class="mt-4"
                  :mainTabName="'Reports'"
                />
      <vx-card>
        <div slot="no-body" class="tabs-container px-6 pt-6">
       
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
       
             <vs-tab
              :label="$t('History Scan')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <patient-tab-history-scan class="mt-4" :patientMedicalHistory="patientMedicalHistory" />
              </div>
            </vs-tab>

             <vs-tab
              :label="$t('History Report')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <patient-tab-history-report class="mt-4" :patientMedicalHistory="patientMedicalHistory" />
              </div>
            </vs-tab>

             <vs-tab
              :label="$t('History Analysis')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <patient-tab-history-analysis class="mt-4" :patientMedicalHistory="patientMedicalHistory" />
              </div>
            </vs-tab>
           
            <!-- <vs-tab
              :label="$t('History Scan')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <patient-tab-history-scan class="mt-4" :patientMedicalHistory="patientMedicalHistory" />
              </div>
            </vs-tab> -->
         

        
           
          </vs-tabs>
        </div>
      </vx-card>
        <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
           
            <vs-button type="border" color="danger" :to="{ name: 'Patient' }">{{
              $t("back")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PatientTabHistoryScan from '../PatientMedicalHistoryLists/PatientTabHistoryScan';
import modulePatient from "@/store/Patient/modulePatient.js";
import PatientTabHistoryReport from '../PatientMedicalHistoryLists/PatientTabHistoryReport.vue';
import PatientTabHistoryAnalysis from '../PatientMedicalHistoryLists/PatientTabHistoryAnalysis.vue';
import patientTab from "@/views/tabs/patientTab.vue";
export default {
  components: {
    PatientTabHistoryScan,
    PatientTabHistoryReport,
    PatientTabHistoryAnalysis,
    patientTab
  },
  data() {
    return {
     
      patient_not_found: false,
      activeTab: 0,
      patientModel:{},
        patientMedicalHistory:{
          PatientMedicalHistoryScans:[],
          PatientMedicalHistoryReports:[],
          PatientMedicalHistoryAnalysis:[]
        }
      
    };
  },
  computed: {
    Currencies() {
      return this.$store.state.CurrencyList.Currencies;
    },
  
    isFormValid() {
      return !this.errors.any() && this.patientMedicalHistory.NameEN;
    }
  },
  methods: {
   getAnalysis(id){
     var model = {};
     model.PatientId = id;
     this.$store.dispatch("patientList/GetPatientReportAnalysis", model).then(res => {
       if( res.data.Data!=null){
        this.patientMedicalHistory.PatientMedicalHistoryAnalysis = res.data.Data;
         this.$store.commit("patientList/SET_PatientMedicalHistoryAnalysis", res.data.Data);
       }
      });
   },
      getReports(id){
     var model = {};
     model.PatientId = id;
     this.$store.dispatch("patientList/GetPatientReport", model).then(res => {
       if( res.data.Data!=null){
        this.patientMedicalHistory.PatientMedicalHistoryReports = res.data.Data;
         this.$store.commit("patientList/SET_PatientMedicalHistoryReports", res.data.Data);
       }
      });
   },
    getScans(id){
     var model = {};
     model.PatientId = id;
     this.$store.dispatch("patientList/GetPatientScans", model).then(res => {
       if( res.data.Data!=null){
        this.patientMedicalHistory.PatientMedicalHistoryScans = res.data.Data;
         this.$store.commit("patientList/SET_PatientMedicalHistoryScans", res.data.Data);
       }
      });
   },
    initValues() {
       this.patientMedicalHistory = {};       
        this.patientMedicalHistory.PatientMedicalHistoryScans=[];
        this.patientMedicalHistory.PatientMedicalHistoryReports=[];
        this.patientMedicalHistory.PatientMedicalHistoryAnalysis=[];
        
    },
 
  },
  created() {
  
    this.initValues();
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    
    // var vm = this;
    const ID = this.$route.params.ID;
    if (ID != undefined) {
       this.$store.dispatch("patientList/GetPatient", ID).then(res => {
        this.patientModel = res.data.Data;
this.$store.commit("SET_SetTitle",''+ this.patientModel.NameEN);

      });
      this.getAnalysis(ID);
      this.getReports(ID);
      this.getScans(ID);

      
    }
  }
};
</script>
